/* IMPORTS */
import React from 'react';
import SEO from '../components/seo';
import { StaticQuery, graphql } from 'gatsby';
//import Img from 'gatsby-image';
import { FaAt, FaPhone, FaMapMarkedAlt } from 'react-icons/fa';
import carte from '../images/Carte.svg';

import styled from 'styled-components';

/* CONTENT */
const Contact = () => (
  <StaticQuery
    query={graphql`
      query contactQuery {
        file(relativePath: { regex: "/contact/" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <SEO title="Contact" />
        <SectionColmuns>
          <article>
            <div>
              <h2 style={{ marginTop: 0, fontWeight: 500 }}>
                <span>Studio </span> <span>bc</span>
              </h2>
              <SocialList>
                <li>
                  <a href="https://www.google.com/maps?ll=48.826437,2.353804&z=16&t=m&hl=en&gl=LU&mapclient=embed&q=8+Passage+du+Moulinet+75013+Paris+France">
                    {' '}
                    <FaMapMarkedAlt />{' '}
                    <span style={{ fontFamily: 'Roboto' }}>
                      Paris - Strasbourg
                    </span>
                  </a>
                </li>
                <li>
                  <a href="mailto:contact@studio-bc.fr">
                    <FaAt />
                    <span style={{ fontFamily: 'Roboto' }}>
                      contact@studio-bc.fr
                    </span>
                  </a>
                </li>
                <li>
                  <a href="tel:+33679683294">
                    <FaPhone />
                    <span style={{ fontFamily: 'Roboto' }}>
                      Charline Battin <br /> (+33 (0) 6 79 68 32 94)
                    </span>
                  </a>
                </li>
                <li>
                  <a href="tel:+33658880678">
                    <FaPhone />
                    <span style={{ fontFamily: '"Roboto", sans-serif' }}>
                      Baptiste Couëdellot <br /> (+33 (0) 6 58 88 06 78)
                    </span>
                  </a>
                </li>
              </SocialList>
            </div>
          </article>
          <article>
            <img src={carte} alt="" srcset="" />
          </article>
        </SectionColmuns>
      </>
    )}
  />
);

export default Contact;

/* STYLE */
const SectionColmuns = styled.section`
  display: grid;
  grid-template-columns: 1fr 1.3fr;
  grid-gap: 40px;

  img {
    width: 100%;
  }

  @media screen and (max-width: 700px) {
    display: flex;
    flex-direction: column;
    article {
      padding-bottom: 1.5rem;
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
`;
const SocialList = styled.ul`
  list-style: none;
  padding: 0;
  li {
    padding: 1rem 0;
    a {
      text-decoration: none;
      font-size: 1rem;
      color: black;
      *:first-child {
        font-size: 1.5rem;
        padding-right: 0.5rem;
        position: relative;
        top: 0.4rem;
      }
    }
  }
`;
